import axios from 'axios'

export function getResetPasswordConfirmation (token) {
  return axios.get('/auth/find/' + token)
  .then(response => {
      return response.data
  })
}

export function updateUserPassword (object) {
  return axios.post('/auth/reset', object)
  .then(response => {
      return response.data
  })
}

export function getAllCandidatos () {
  return axios.get('/users/candidatos')
  .then(response => {
    return response.data
  })
}

export function bloquearCandidato (userId) {
  return axios.post('/users/bloquear', { userId: userId })
  .then(response => {
    return response.data
  })
}

export function desbloquearCandidato (userId) {
  return axios.post('/users/desbloquear', { userId: userId })
  .then(response => {
    return response.data
  })
}

export function getEstadoBloqueo () {
  return axios.get('/users/estado-bloqueo')
  .then(response => {
      return response.data
  })
}

export default {
  getResetPasswordConfirmation,
  updateUserPassword,
  getAllCandidatos,
  bloquearCandidato,
  desbloquearCandidato,
  getEstadoBloqueo,
}
