<template>
  <v-container
    fluid
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <!-- Timer -->
    <div class="base-timer">
      <svg
        class="base-timer__svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g class="base-timer__circle">
          <circle
            class="base-timer__path-elapsed"
            cx="50"
            cy="50"
            r="45"
          />
          <path
            :stroke-dasharray="circleDasharray"
            class="base-timer__path-remaining"
            :class="remainingPathColor"
            d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            "
          />
        </g>
      </svg>
      <span class="base-timer__label">{{ formattedTimeLeft }}</span>
    </div>
    <!-- /Timer -->
    <div>
      <h1>Test Personalidad</h1>
    </div>
    <v-row
      justify="center"
    >
      <v-col
        id="pregunta-1"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="1"
            /><br><br>
            Entusiasta<br>
            Rápido/a<br>
            Lógico/a<br>
            Apacible
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p1mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p1mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p1mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p1mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p1menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p1menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p1menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p1menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-2"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="2"
            />
            <br><br>
            Cauteloso/a<br>
            Decidido/a<br>
            Receptivo/a<br>
            Bondadoso/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p2mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p2mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p2mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p2mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p2menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p2menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p2menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p2menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-3"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="3"
            /><br><br>
            Amigable<br>
            Preciso/a<br>
            Franco/a<br>
            Transquilo/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p3mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p3mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p3mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p3mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p3menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p3menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p3menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p3menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-4"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="4"
            /><br><br>
            Elocuente<br>
            Controlador/a<br>
            Tolerante<br>
            Decisivo/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p4mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p4mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p4mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p4mas"
              value="d-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p4menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p4menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p4menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p4menos"
              value="d-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        id="pregunta-5"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="5"
            /><br><br>
            Atrevido/a<br>
            Concienzudo/a<br>
            Comunicativo/a<br>
            Moderado/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p5mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p5mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p5mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p5mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p5menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p5menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p5menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p5menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-6"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="6"
            />
            <br><br>
            Ameno/a<br>
            Ingenioso/a<br>
            Investigador/a<br>
            Acepta riesgos
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p6mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p6mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p6mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p6mas"
              value="d-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p6menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p6menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p6menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p6menos"
              value="d-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-7"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="7"
            /><br><br>
            Expresivo/a<br>
            Cuidadoso/a<br>
            Dominante<br>
            Sensible
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p7mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p7mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p7mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p7mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p7menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p7menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p7menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p7menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-8"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="8"
            /><br><br>
            Extrovertido/a<br>
            Precavido/a<br>
            Constante<br>
            Impaciente
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p8mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p8mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p8mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p8mas"
              value="d-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p8menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p8menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p8menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p8menos"
              value="d-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        id="pregunta-9"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="9"
            /><br><br>
            Discreto/a<br>
            Complaciente<br>
            Encantador/a<br>
            Insistente
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p9mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p9mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p9mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p9mas"
              value="d-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p9menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p9menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p9menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p9menos"
              value="d-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-10"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="10"
            />
            <br><br>
            Valeroso/a<br>
            Anima a los demás<br>
            Pacífico/a<br>
            Perfeccionista
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p10mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p10mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p10mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p10mas"
              value="c-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p10menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p10menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p10menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p10menos"
              value="c-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-11"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="11"
            /><br><br>
            Reservado/a<br>
            Atento/a<br>
            Osado/a<br>
            Alegre
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p11mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p11mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p11mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p11mas"
              value="i-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p11menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p11menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p11menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p11menos"
              value="i-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-12"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="12"
            /><br><br>
            Estimulante<br>
            Gentil<br>
            Perceptivo/a<br>
            Independiente
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p12mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p12mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p12mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p12mas"
              value="d-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p12menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p12menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p12menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p12menos"
              value="d-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        id="pregunta-13"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="13"
            /><br><br>
            Competitivo/a<br>
            Considerado/a<br>
            Alegre<br>
            Sagaz
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p13mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p13mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p13mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p13mas"
              value="c-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p13menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p13menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p13menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p13menos"
              value="c-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-14"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="14"
            />
            <br><br>
            Meticuloso<br>
            Obediente<br>
            Ideas firmes<br>
            Moderado
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p14mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p14mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p14mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p14mas"
              value="i-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p14menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p14menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p14menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p14menos"
              value="i-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-15"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="15"
            /><br><br>
            Popular<br>
            Reflexivo/a<br>
            Tenaz<br>
            Calmado/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p15mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p15mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p15mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p15mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p15menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p15menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p15menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p15menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-16"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="16"
            /><br><br>
            Analítico/a<br>
            Audaz<br>
            Leal<br>
            Promotor/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p16mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p16mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p16mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p16mas"
              value="i-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p16menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p16menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p16menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p16menos"
              value="i-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        id="pregunta-17"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="17"
            /><br><br>
            Sociable<br>
            Paciente<br>
            Auto suficiente<br>
            Certero/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p17mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p17mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p17mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p17mas"
              value="c-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p17menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p17menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p17menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p17menos"
              value="c-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-18"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="18"
            />
            <br><br>
            Adaptable<br>
            Resuelto/a<br>
            Prevenido/a<br>
            Vivaz
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p18mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p18mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p18mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p18mas"
              value="i-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p18menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p18menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p18menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p18menos"
              value="i-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-19"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="19"
            /><br><br>
            Agresivo/a<br>
            Impetuoso/a<br>
            Amistoso/a<br>
            Diferenciar
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p19mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p19mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p19mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p19mas"
              value="c-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p19menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p19menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p19menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p19menos"
              value="c-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-20"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="20"
            /><br><br>
            De trato fácil<br>
            Compasivo/a<br>
            Cauto/a<br>
            Habla directo
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p20mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p20mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p20mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p20mas"
              value="d-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p20menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p20menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p20menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p20menos"
              value="d-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        id="pregunta-21"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="21"
            /><br><br>
            Evaluador/a<br>
            Generoso/a<br>
            Animado/a<br>
            Persistente
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p21mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p21mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p21mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p21mas"
              value="d-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p21menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p21menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p21menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p21menos"
              value="d-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-22"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="22"
            />
            <br><br>
            Impulsivo<br>
            Cuida los detalles<br>
            Enérgico/a<br>
            Tranquilo/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p22mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p22mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p22mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p22mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p22menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p22menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p22menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p22menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-23"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="23"
            /><br><br>
            Sociable<br>
            Sistemático/a<br>
            Vigoroso/a<br>
            Tolerante
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p23mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p23mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p23mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p23mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p23menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p23menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p23menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p23menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-24"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="24"
            /><br><br>
            Cautivador/a<br>
            Contento/a<br>
            Exigente<br>
            Apegado a normas
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p24mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p24mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p24mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p24mas"
              value="c-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p24menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p24menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p24menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p24menos"
              value="c-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        id="pregunta-25"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="25"
            /><br><br>
            Le agrada discutir<br>
            Metódico/a<br>
            Comedido/a<br>
            Desenvuelto/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p25mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p25mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p25mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p25mas"
              value="i-imas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p25menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p25menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p25menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p25menos"
              value="i-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-26"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="26"
            />
            <br><br>
            Jovial<br>
            Preciso/a<br>
            Directo/a<br>
            Ecuánime
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p26mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p26mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p26mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p26mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p26menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p26menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p26menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p26menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-27"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="27"
            /><br><br>
            Inquieto/a<br>
            Amable<br>
            Elocuente<br>
            Cuidadoso/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p27mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p27mas"
              value="s-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p27mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p27mas"
              value="c-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p27menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p27menos"
              value="s-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p27menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p27menos"
              value="c-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>

      <v-col
        id="pregunta-28"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        class="q"
      >
        <v-row>
          <v-col
            cols="8"
          >
            <v-badge
              color="grey"
              content="28"
            /><br><br>
            Prudente<br>
            Pionero<br>
            Espontáneo/a<br>
            Colaborador/a
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Más<br><br>
            <input
              type="radio"
              name="p28mas"
              value="c-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p28mas"
              value="d-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p28mas"
              value="i-mas"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p28mas"
              value="s-mas"
              @click="validarIguales"
            >
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            Menos<br><br>
            <input
              type="radio"
              name="p28menos"
              value="c-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p28menos"
              value="d-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p28menos"
              value="i-menos"
              @click="validarIguales"
            ><br>
            <input
              type="radio"
              name="p28menos"
              value="s-menos"
              @click="validarIguales"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div cols="12">
      <br>
      <p class="text-center">
        <v-btn
          id="submit"
          color="primary"
          @click="prepararEnvio()"
        >
          Enviar resultados
        </v-btn>
      </p>
    </div>
  </v-container>
</template>

<script>
  import TestApi from '@/services/api/Test'
  import UsuariosApi from '@/services/api/Usuarios'

  // Timer
  const FULL_DASH_ARRAY = 283
  const TIME_LIMIT = 480
  const WARNING_THRESHOLD = 240
  const ALERT_THRESHOLD = 120
  const COLOR_CODES = {
    info: {
      color: 'green',
    },
    warning: {
      color: 'orange',
      threshold: WARNING_THRESHOLD,
    },
    alert: {
      color: 'red',
      threshold: ALERT_THRESHOLD,
    },
  }
  // /Timer

  export default {
    name: 'EnviarTestComponent',
    props: {
      role: {
        type: String,
        default: '',
      },
    },
    data: function () {
      return {
        roleName: this.role,
        colorSnak: 'info',
        snackbar: false,
        text: '',
        timeout: 3000,
        timePassed: 0, // Timer
        timerInterval: null, // Timer
        fecha: new Date().toISOString(),
        horaInicio: new Date().toISOString(),
        horaFin: new Date().toISOString(),
      }
    },
    computed: {
      // Timer
      circleDasharray () {
        return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`
      },

      formattedTimeLeft () {
        const timeLeft = this.timeLeft
        const minutes = Math.floor(timeLeft / 60)
        let seconds = timeLeft % 60

        if (seconds < 10) {
          seconds = `0${seconds}`
        }

        return `${minutes}:${seconds}`
      },

      timeLeft () {
        return TIME_LIMIT - this.timePassed
      },

      timeFraction () {
        const rawTimeFraction = this.timeLeft / TIME_LIMIT
        return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction)
      },

      remainingPathColor () {
        const { alert, warning, info } = COLOR_CODES
        if (this.timeLeft <= alert.threshold) {
          return alert.color
        } else if (this.timeLeft <= warning.threshold) {
          return warning.color
        } else {
          return info.color
        }
      },
      // /Timer
    },
    watch: {
      // Timer
      timeLeft (newValue) {
        if (newValue === 0) {
          this.onTimesUp()
          this.horaFin = new Date().toISOString()
          this.revisarTest(true)
        }
      },
      // /Timer
    },
    created () {
      if (localStorage.getItem('bloqueado') !== 'null' || localStorage.getItem('fecha_consentimiento') === 'null') {
        this.$router.push({ path: 'inicio' })
      }
    },
    mounted () {
      // Timer
      this.startTimer()
    },
    methods: {
      validarIguales: function (event) {
        var iname = event.target.name
        var ivalue = event.target.value
        var baseName = ''
        var inputsOpuestos = null
        if (ivalue.includes('mas')) {
          baseName = iname.split('mas')[0]
          inputsOpuestos = document.getElementsByName(baseName + 'menos')
        }
        if (ivalue.includes('menos')) {
          baseName = iname.split('menos')[0]
          inputsOpuestos = document.getElementsByName(baseName + 'mas')
        }
        for (let count = 0, l = inputsOpuestos.length; count < l; count++) {
          if (ivalue.split('-')[0] === inputsOpuestos[count].value.split('-')[0]) {
            if (inputsOpuestos[count].checked) {
              this.text = 'No puede seleccionar tanto la opción "más" como la "menos" en el mismo término'
              this.colorSnak = 'error'
              this.snackbar = true
              event.target.checked = false
            }
          }
        }
      },
      prepararEnvio () {
        this.horaFin = new Date().toISOString()
        this.revisarTest(false)
      },
      revisarTest (bloquear) {
        var conteo = 0
        var objetoFinal = []
        var respuestas = []
        var general = {
          fecha: this.fecha,
          hora_inicio: this.horaInicio,
          hora_fin: this.horaFin,
          fecha_consentimiento: localStorage.getItem('fecha_consentimiento'),
        }
        objetoFinal.push(general)
        for (let i = 1; i <= 28; i++) {
          var inputs = document.getElementById('pregunta-' + i).getElementsByTagName('input')
          let mas = null
          let menos = null
          for (let count = 0, l = inputs.length; count < l; count++) {
            if (inputs[count].checked) {
              switch (inputs[count].name) {
                case 'p' + i + 'mas': mas = inputs[count].value.split('-')[0]; break
                case 'p' + i + 'menos': menos = inputs[count].value.split('-')[0]; break
              }
            }
          }
          if (mas == null) mas = 'n/a'
          if (menos == null) menos = 'n/a'
          var respuesta = {
            id_pregunta: i,
            mas: mas,
            menos: menos,
          }
          respuestas.push(respuesta)
        }
        objetoFinal.push(respuestas)
        for (let i = 0; i <= 27; i++) {
          conteo++
          if (objetoFinal[1][i].mas === 'n/a' || objetoFinal[1][i].menos === 'n/a') {
            conteo--
          }
        }
        if (conteo === 28) {
          this.saveTest(objetoFinal)
        } else {
          if (bloquear) {
            UsuariosApi.bloquearCandidato(localStorage.getItem('id'))
              .then((response) => {
                localStorage.setItem('bloqueado', 'bloqueado')
                this.$router.push({ path: 'bloqueo' })
              })
              .catch((error) => {
                console.log(error)
                this.text = 'Algo ha ocurrido mal'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          } else {
            this.text = 'Ha respondido ' + conteo + ' de 28 preguntas. Revise el cuestionario.'
            this.colorSnak = 'error'
            this.snackbar = true
          }
        }
      },
      saveTest (objetoFinal) {
        TestApi.postTest(objetoFinal)
          .then(response => {
            localStorage.setItem('bloqueado', 'bloqueado')
            localStorage.setItem('fecha_consentimiento', 'null')
            this.$router.push({ path: 'fin' })
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
      // Timer
      onTimesUp () {
        clearInterval(this.timerInterval)
      },
      startTimer () {
        this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
      },
      // /Timer
    },
  }
</script>

<style scoped lang="scss">
.q {
  border-bottom: 1px solid #CCC;
  padding: 1em 2em 1em 1em;
}
.q:nth-of-type(even) {
  background-color: #F5F5F5;
}
.base-timer {
  position: fixed;
  width: 100px;
  height: 100px;
  z-index: 999;
  right: 5%;
  top:8%;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
  }
}
</style>
