import axios from 'axios'

export function getTestCandidato (userId) {
  return axios.get('/test/test-usuario/' + userId)
  .then(response => {
      return response.data
    })
}

export function getTotalesTestCandidato (testId) {
  return axios.get('/test/test/' + testId)
  .then(response => {
      return response.data
    })
}

export function postTest (objetoFinal) {
  return axios.post('/test/test-usuario', objetoFinal)
  .then(response => {
      return response.data
    })
}

export function getTestPdf (testId) {
  return axios.get('/test/test-pdf/' + testId, { responseType: 'arraybuffer' })
  .then(response => {
      return response.data
    })
}

export default {
  getTestCandidato,
  getTotalesTestCandidato,
  postTest,
  getTestPdf,
}
